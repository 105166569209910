/*!
 * Start Bootstrap - Agency v5.2.1 (https://startbootstrap.com/template-overviews/agency)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-agency/blob/master/LICENSE)
 */
 :root{
  --black: #000000;
  --white: #ffffffff;
  --blue: #2a3b8e;
 }

 /* Fonts */
  @font-face {
  font-family: 'orbitronbold';
    src: url('orbitron-bold-webfont.woff2') format('woff2'),
         url('orbitron-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
/**************************************/

/* Global Classes */

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

.fadeInAnimated {
  opacity: 1;
  animation: fadeIn 10s forwards; /* forwards (animation-fill-mode) retains the style from the last keyframe when the animation ends */
}

p{
    font-size: 18px;
    line-height: 1.75;
    color: var(--black);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
  
  li{
    font-size: 18px;
    color: var(--black);
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }

  .section{
  color: var(--white);
  background-color: #2a3b8e; 
  height:100vh;
  display:flex;
  justify-content: center;
  align-items: center;
  }

  .alignLeft { 
    text-align: justify;  /* For Edge */
    text-align-last: left;
  }
  
  .alignRight { 
    text-align: justify;  /* For Edge */
    text-align-last: right;
  }
/**************************************/

/* Nav bar */
@media(max-width: 899px){
  .nav-img{
    display: none;
  }
}
@media(min-width: 900px){
  .nav-img-mobile{
    display: none;
  }
}
.nav-text {
  font-size: 16px;
  height : 100px;
  line-height: 85px;  
  text-align: left;
  font-family: 'orbitronbold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.nav-text-content{
  color: #2a3b8e;
  display : inline-block;
 vertical-align : middle;
 line-height : 20px;
}
  

/**************************************/

/* Landing Page */

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

.fadeInAnimated {
  opacity: 1;
  animation: fadeIn 10s forwards; /* forwards (animation-fill-mode) retains the style from the last keyframe when the animation ends */
}

/**************************************/
